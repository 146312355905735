import React from 'react';
import { useCustomContext } from '../../contexts/customContext';
import '../../themes/index.css';

function SetColors() {
  const customContext = useCustomContext();
  const tab = [
    {
      title: 'TEXTE',
      textColor: '#29CC6A',
      writing: 'Aa',
      background: '#FFFFFF',
    },
    {
      title: 'TEXTE',
      textColor: '#000000',
      writing: 'Aa',
      background: '#ECECF0',
    },
    {
      title: 'TEXTE',
      textColor: '#F97578',
      writing: 'Aa',
      background: '#FCEFDE',
    },
    {
      title: 'TEXTE',
      textColor: '#4F13F4',
      writing: 'Aa',
      background: '#F6E6F0',
    },
  ];

  return (
    <div className="mt-16 flex flex-wrap gap-4">
      <p className="font-bold pb-6 font-neueUltra">Couleurs de fond aléatoires</p>
      {tab.map((tabItem) => (
        <div
          className="customSquare items-center relative hover:cursor-pointer"
          key={tabItem.background}>
          <div
            className="absolute rounded-3xl top-0 left-0 right-0 bottom-0 font-inter flex flex-col p-4 hover:border-black hover:border-solid hover:border-2 border-transparent border-2"
            style={{ backgroundColor: tabItem.background }}
            onClick={() => {
              customContext.onChangeBgColor(tabItem.background);
              customContext.onChangeTextColor(tabItem.textColor);
            }}>
            <div className="flex-1 font-semibold text-xs" style={{ color: tabItem.textColor }}>
              <p className="setTextColorsPadding">{tabItem.title}</p>
              <p className="setColorsPadding">{tabItem.textColor}</p>
            </div>
            <div
              className="text-right font-bold absolute bottom-0 right-0 text-4xl p-4 setWritingPadding"
              style={{ color: tabItem.textColor }}>
              {tabItem.writing}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SetColors;
