import React from 'react';

function ModalErrSecMsg() {
  return (
    <div className="text-red-700 text-xs pl-4">
      <p>Le texte doit faire moins de 80 caractères</p>
    </div>
  );
}
export default ModalErrSecMsg;
