import React from 'react';
import { useCustomContext } from '../../contexts/customContext';

function DiscountsList() {
  const customContext = useCustomContext();
  const reducImages = [
    {
      discount: require('../../assets/images/bonDeReducJaune.png'),
      text: 'Bon n°1',
      alt: 'Bon d&apos;achat 2€',
    },
    {
      discount: require('../../assets/images/bonDeReducBleuClair.png'),
      text: 'Bon n°2',
      alt: 'Bon de réduction de 2€',
    },
    {
      discount: require('../../assets/images/bonDeReducVert.png'),
      text: 'Bon n°3',
      alt: 'Bon de réduction de 10%',
    },
    {
      discount: require('../../assets/images/bonDeReducViolet.png'),
      text: 'Bon n°4',
      alt: 'Bon de réduction pour le parking',
    },
  ];

  return (
    <div className="w-full mt-16">
      <p className="pb-6 font-neueUltra">Bons de réduction</p>
      <p className="font-inter mb-8">
        Créez vos encarts promotionnels dans la rubrique “Bons de réduction” puis organisez ici leur
        ordre d’apparition en bas de votre ticket. Pour organiser l’ordre d’apparition, glissez et
        déposez vos bons de réductions.
      </p>
      {reducImages.map((reducImagesItem) => (
        <button
          onClick={() => {
            customContext.onChangeDiscount(reducImagesItem.discount);
          }}
          type="button"
          key={reducImagesItem.discount}
          className="text-left">
          <div className="font-inter font-bold uppercase pb-6">
            <p>{reducImagesItem.text}</p>
            <img
              src={reducImagesItem.discount}
              alt={reducImagesItem.alt}
              className="rounded-3xl border-transparent border-2 hover:border-solid hover:border-2 hover:border-black hover:cursor-pointer"
            />
          </div>
        </button>
      ))}
    </div>
  );
}

export default DiscountsList;
