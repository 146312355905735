import React, { useState } from 'react';
import { link } from 'fs';
import ModifyLogo from '../../components/ticketViewComponents/ModifyLogo';
import ModalModifyLogo from '../../components/ticketViewComponents/ModalModifyLogo';
import SetColors from '../../components/ticketViewComponents/SetColors';
import ShopInfos from '../../components/ticketViewComponents/ShopInfos';
import CustomMessage from '../../components/ticketViewComponents/CustomMessage';
import MainMessage from '../../components/ticketViewComponents/MainMessage';
import ModalCustomMsg from '../../components/ticketViewComponents/ModalCustomMsg';
import SecondMsg from '../../components/ticketViewComponents/SecondaryMsg';
import DiscountsList from '../../components/ticketViewComponents/DiscountsList';
import { useCustomContext } from '../../contexts/customContext';
import ModalNoLogo from '../../components/ticketViewComponents/ModalNoLogo';

// TicketCustom
function LeftCustom() {
  const useContext = useCustomContext();
  const [modalNoLogo, setModalNoLogo] = useState(false);

  return (
    <div className="w-full h-full bg-mainBg">
      <div className="flex-initial w-[35vw]">
        <div className="mx-[6vw] mt-[6vw] mb-[4vw]">
          <p className="text-[4vw] font-['sharpGrotesk'] uppercase tracking-normal leading-[1] text-black">
            Ticket <br />
            de caisse
          </p>
          <p className="mt-[2vw] font-inter font-semibold leading-[1.2] text-lg text-black">
            Personnalisez votre propre ticket de caisse (logo, couleur, fond, message, promo)
          </p>
          <div className="flex flex-row mt-10 relative">
            <p className="flex-1 font-neueUltra font-extrabold">Votre logo</p>
            {useContext.logo === null && (
              <a
                className="flex-start hover:text-white hover:cursor-pointer font-inter"
                href="#shopInfos"
                onMouseEnter={() => setModalNoLogo(true)}
                onMouseLeave={() => setModalNoLogo(false)}>
                Je n'ai pas de logo
              </a>
            )}
            {modalNoLogo ? <ModalNoLogo setModalNoLogo={setModalNoLogo} /> : null}
            {useContext.logo !== null && (
              <button
                type="button"
                onClick={() => {
                  useContext.onChangeLogo(null);
                }}>
                <p className="flex-end pl-[10vw] font-inter cursor-pointer hover:text-white hover:underline">
                  Supprimer mon logo
                </p>
              </button>
            )}
          </div>
          <div>
            <ModifyLogo />
            <ModalModifyLogo />
            <SetColors />
            <ShopInfos />
            <CustomMessage />
            <div className="mt-4 flex flex-row items-center justify-center space-x-1">
              <hr className="border-black rounded-xs border-2 w-[2.5vw] hover:border-redColor hover:cursor-pointer" />
            </div>
            <ModalCustomMsg
              title="EXEMPLES MESSAGES"
              messages={[
                'Les échanges se font sous 15 jours sur présentation de ce ticket et des étiquettes de l’emballage d’origine.',
                'Promotion de -20% sur tous les articles du rayon alimentaire',
              ]}
            />
            <MainMessage />
            <ModalCustomMsg
              title="EXEMPLES MESSAGES"
              messages={[
                'Venez profiter de nos super promotions!',
                'Les échanges se font sous 15 jours sur présentation de ce ticket et des étiquettes de l’emballage d’origine.',
              ]}
            />
            <SecondMsg />
            <ModalCustomMsg
              title="EXEMPLES MESSAGES"
              messages={[
                'Si vous possédez ce produit, ne le consommez pas!',
                'Fin de nos promotions dans quinze jours',
              ]}
            />
            <DiscountsList />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftCustom;
