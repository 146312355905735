import React, { useEffect, useState } from 'react';
import '../../themes/absolute.css';

type ModalMsgCopiedProps = {
  setModalMsgCopied: (value: boolean) => void;
};

export default function ModalMsgCopied({ setModalMsgCopied }: ModalMsgCopiedProps) {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      setModalMsgCopied(false);
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [setModalMsgCopied]);

  if (!visible) {
    return null;
  }

  return (
    <div className="mt-6 absolute bottom-[105%] ml-[90%] font-neueRegular text-sm right-0 rounded-xl flex flex-col bg-[#FFEF5C] box-border p-4 modalTransition">
      <img
        src={require('../../assets/images/losangeModaleJaune.png')}
        alt="Losange flèche haut"
        className="absolute justify-center h-[20px] w-[20px] border-solid topPositionModal"
      />
      <p>Message copié!</p>
    </div>
  );
}
