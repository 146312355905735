/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
/* eslint-disable no-alert */
import React, { useState } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';
import '../../themes/absolute.css';
import ModalCopyMsg from './ModalCopyMsg';
import ModalMsgCopied from './ModalMsgCopied';

interface ModalCustomMsgProps {
  messages: string[];
  title: string;
}

function ModalCustomMsg(props: ModalCustomMsgProps) {
  const { title, messages } = props;

  const [, copyMsg] = useCopyToClipboard();
  const [modalCopyMsg, setModalCopyMsg] = useState(false);
  const [modalMsgCopied, setModalMsgCopied] = useState(false);

  return (
    <div className="w-full">
      <div className="relative rounded-2xl pb-4 mt-8 mb-12 flex-col bg-modalsBg flex text-xs box-border">
        <img
          src={require('../../assets/images/losangeModale.png')}
          alt="Losange flèche haut"
          className="absolute justify-center h-[20px] w-[20px] border-solid centerPositionSquare"
        />
        <div className="relative rounded-3xl bg-modalsBg box-border ">
          {messages.map((message, index) => (
            <>
              <div className="px-6 py-6">
                {index === 0 && (
                  <div>
                    <p className="uppercase text-redColor font-inter font-bold pb-4">{title}</p>
                  </div>
                )}
                <div className="flex flex-row">
                  <p className="flex-1 text-left text-sm font-inter font-[600] items-start">
                    {message}
                  </p>
                  <div className="flex items-center pl-2">
                    <button
                      type="button"
                      onClick={() => {
                        copyMsg(message);
                        setModalMsgCopied(true);
                      }}
                      onMouseEnter={() => setModalCopyMsg(true)}
                      onMouseLeave={() => setModalCopyMsg(false)}>
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <rect width="32" height="32" rx="8" fill="#222222" />
                        <path
                          d="M19.8005 11.8193H8.63428V22.9855H19.8005V11.8193Z"
                          stroke="#D2DEDF"
                          strokeWidth="1.26889"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.8193 8.63477V11.8197H19.8006V19.801H22.9855V8.63477H11.8193Z"
                          stroke="#D2DEDF"
                          strokeWidth="1.26889"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    {modalCopyMsg ? <ModalCopyMsg setModalCopyMsg={setModalCopyMsg} /> : null}
                    {modalMsgCopied ? (
                      <ModalMsgCopied setModalMsgCopied={setModalMsgCopied} />
                    ) : null}
                  </div>
                </div>
              </div>
              {messages.length - 1 !== index && (
                <hr className="border-solid border-mainBg border-1" />
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
export default ModalCustomMsg;
