/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
import React, { useEffect, useState } from 'react';
import '../themes/index.css';
import { Link } from 'react-router-dom';
import ModalWhatsApp from './navbarComponents/ModalWhatsApp';
import ModalMail from './navbarComponents/ModalMail';
// import { useLazyGetShopsQuery } from '../services/modules/stores';
import { useAppSelector } from '../utils/hooks/redux';

function Navbar() {
  // const { haveSignIn } = useAppSelector((state) => state.user);

  // const links = [
  //   { name: 'Data', url: '/dataview' },
  //   { name: 'Ticket de caisse', url: '/mainview' },
  //   { name: 'Licence de caisse', url: '/cashlicence' },
  //   { name: 'Corner shop', url: '/cornershop' },
  //   { name: 'Utilisateurs', url: '/users' },
  //   { name: 'Welcome pack', url: '/welcomepack' },
  //   { name: 'Factures', url: '/invoices' },
  // ];
  const [modalWhatsApp, setModalWhatsApp] = useState(false);
  const [modalMail, setModalMail] = useState(false);
  const handleClickWhatsApp = () => {
    setModalWhatsApp(!modalWhatsApp);
  };
  const handleClickMail = () => {
    setModalMail(!modalMail);
  };

  return (
    <div className="flex h-full bg-black">
      <div className="w-[19vw] h-full bg-black fixed">
        <div className="mx-[5.5vw] mt-[7.5vw] mb-[3vw] ">
          <img src={require('../assets/images/logoQuipo.png')} id="logoQuipo" alt="Logo Quipo" />
        </div>
        <div className="text-white mx-[5.5vw] font-neueLight text-2xl">
          <svg>
            <svg
              width="139"
              height="136"
              viewBox="0 0 139 136"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M138.754 67.7374C138.754 72.8731 134.085 77.4311 132.996 82.2329C131.907 87.0354 134.064 93.3047 131.907 97.7957C129.75 102.287 123.547 104.421 120.413 108.334C117.278 112.247 116.611 118.739 112.676 121.874C108.741 125.008 102.315 124.208 97.7578 126.409C93.1997 128.61 89.887 134.124 84.9071 135.257C79.9272 136.392 74.6358 132.857 69.5001 132.857C64.3644 132.857 58.8952 136.369 54.093 135.257C49.2908 134.146 45.7336 128.566 41.2426 126.409C36.7517 124.253 30.2375 124.986 26.3246 121.874C22.4117 118.761 21.7225 112.27 18.5877 108.334C15.4529 104.399 9.29448 102.331 7.09347 97.7957C4.89245 93.2606 7.13796 87.2135 6.00407 82.2329C4.87021 77.253 0.24585 72.8731 0.24585 67.7374C0.24585 62.6018 4.91468 58.0442 6.00407 53.242C7.09347 48.4397 4.93691 42.1702 7.09347 37.6792C9.24999 33.1882 15.4529 31.0539 18.5877 27.141C21.7225 23.2281 22.3894 16.7362 26.3246 13.6014C30.2598 10.4666 36.685 11.267 41.2426 9.06598C45.8003 6.86497 49.1129 1.32904 54.093 0.217419C59.073 -0.894207 64.3644 2.61853 69.5001 2.61853C74.6358 2.61853 80.1053 -0.894207 84.9071 0.217419C89.7096 1.32904 93.2668 6.90939 97.7578 9.06598C102.249 11.2225 108.762 10.4888 112.676 13.6014C116.589 16.7139 117.278 23.2058 120.413 27.141C123.547 31.0761 129.706 33.1437 131.907 37.6792C134.108 42.2146 131.862 48.2618 132.996 53.242C134.13 58.222 138.754 62.6018 138.754 67.7374Z"
                fill="#FD8851"
              />
              <path
                d="M31.103 43.2594C35.7718 35.9671 42.4416 30.0755 50.3563 26.4071"
                stroke="#1D1D1B"
                strokeWidth="2.61236"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M107.9 92.2157C103.231 99.5075 96.561 105.4 88.6462 109.068"
                stroke="#1D1D1B"
                strokeWidth="2.61236"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M60.2478 18.6927L63.3825 24.8956H63.4715L64.7833 18.0479L66.0949 17.8701L64.3384 26.274L62.9379 26.4741L58.936 18.8706L60.2478 18.6927Z"
                fill="black"
              />
              <path
                d="M72.1713 26.185C71.5713 26.1405 71.06 25.9849 70.6598 25.7181C70.2596 25.4513 69.9258 25.0511 69.726 24.5842C69.5256 24.0951 69.4369 23.5393 69.4815 22.9168C69.5256 22.2943 69.659 21.7607 69.9258 21.2938C70.1926 20.8269 70.5264 20.4934 70.9707 20.2489C71.4156 20.0043 71.9045 19.9154 72.4604 19.9376C72.7943 19.9376 73.1274 20.0266 73.4389 20.1599C73.7498 20.2933 74.0389 20.4934 74.261 20.738C74.5061 21.0048 74.6836 21.3161 74.817 21.7163C74.9504 22.1164 74.9951 22.5833 74.9504 23.1391V23.5616L70.1039 23.2725L70.1486 22.3832L73.7945 22.6055C73.7945 22.2943 73.7721 22.0275 73.6611 21.7607C73.55 21.5162 73.3942 21.3161 73.1721 21.1604C72.9494 21.0048 72.6826 20.9159 72.394 20.8936C72.0602 20.8936 71.7711 20.9381 71.5043 21.0937C71.2375 21.2494 71.0377 21.4495 70.9043 21.694C70.7709 21.9386 70.6822 22.2276 70.6598 22.5166L70.6151 23.2058C70.5928 23.606 70.6375 23.9617 70.7709 24.2507C70.9043 24.5397 71.0824 24.7621 71.3268 24.9399C71.5713 25.0956 71.8828 25.2067 72.216 25.2067C72.4381 25.2067 72.6385 25.2067 72.8383 25.14C73.0164 25.0956 73.1945 25.0066 73.3279 24.8732C73.4613 24.7398 73.5723 24.6064 73.6611 24.4064L74.773 24.6731C74.6613 25.0066 74.4838 25.2734 74.2393 25.4958C73.9942 25.7181 73.7057 25.896 73.3496 26.0071C72.994 26.1183 72.5938 26.1627 72.1489 26.1405L72.1713 26.185Z"
                fill="black"
              />
              <path
                d="M77.9945 26.8295L79.4173 20.9379L80.5291 21.2047L80.307 22.1384H80.3734C80.5515 21.8717 80.8183 21.6493 81.1298 21.5382C81.4406 21.427 81.7745 21.4048 82.13 21.4937C82.1964 21.4937 82.2857 21.5382 82.3744 21.5604C82.4632 21.5827 82.5525 21.6049 82.5966 21.6271L82.3298 22.7165C82.3298 22.7165 82.2187 22.6498 82.0853 22.6053C81.9742 22.5608 81.8632 22.5164 81.7298 22.4941C81.463 22.4274 81.1962 22.4274 80.974 22.4941C80.7513 22.5608 80.5291 22.672 80.3511 22.8499C80.1736 23.0277 80.0626 23.2279 79.9955 23.4947L79.1287 27.0963L77.9722 26.8295H77.9945Z"
                fill="black"
              />
              <path
                d="M89.8897 26.0517L88.8225 25.8072C88.8225 25.6738 88.8225 25.5182 88.7778 25.3626C88.7331 25.2069 88.6667 25.0735 88.5333 24.9401C88.4223 24.8067 88.2442 24.6955 88.022 24.5844C87.7106 24.451 87.4214 24.4065 87.1546 24.451C86.8878 24.4954 86.7104 24.6288 86.621 24.8512C86.5323 25.0291 86.5546 25.2069 86.621 25.3848C86.7104 25.5626 86.8878 25.7405 87.177 25.9406L87.9774 26.5186C88.4446 26.8521 88.7331 27.2078 88.8888 27.5636C89.0446 27.9193 89.0222 28.2972 88.8448 28.6974C88.7114 29.0309 88.4663 29.2977 88.1778 29.4533C87.8663 29.6312 87.5108 29.7201 87.1106 29.7201C86.7104 29.7201 86.2655 29.6312 85.8206 29.4311C85.1983 29.1643 84.754 28.8085 84.4649 28.3861C84.1981 27.9415 84.1087 27.4746 84.2204 26.941L85.3317 27.2301C85.2876 27.5413 85.3317 27.7859 85.4651 28.0304C85.5985 28.275 85.8429 28.4306 86.1768 28.5862C86.5323 28.7418 86.8438 28.7863 87.1329 28.7196C87.4214 28.6529 87.5995 28.5195 87.6882 28.3194C87.7552 28.1416 87.7552 27.9637 87.6882 27.8081C87.5995 27.6303 87.4438 27.4524 87.2216 27.2745L86.3765 26.6742C85.91 26.3407 85.5985 25.985 85.4651 25.6071C85.3317 25.2514 85.354 24.8512 85.5097 24.4732C85.6431 24.1397 85.8653 23.8952 86.1544 23.7173C86.4436 23.5395 86.7767 23.4728 87.1546 23.4728C87.5325 23.4728 87.9327 23.5617 88.3552 23.7396C88.9559 24.0063 89.3784 24.3398 89.6005 24.74C89.8227 25.1402 89.9114 25.5849 89.845 26.074L89.8897 26.0517Z"
                fill="black"
              />
              <path
                d="M91.0173 31.9436L94.219 26.7856L95.2192 27.4082L92.0175 32.5661L91.0173 31.9436ZM95.2192 26.3187C95.0411 26.2076 94.93 26.0519 94.886 25.8741C94.8413 25.674 94.8637 25.4962 94.9524 25.3405C95.0634 25.1849 95.1968 25.0737 95.3973 25.0293C95.5971 24.9848 95.7751 25.0293 95.9532 25.1404C96.1307 25.2516 96.2417 25.4072 96.2864 25.5851C96.3311 25.7852 96.3087 25.963 96.2194 26.1186C96.1083 26.2743 95.9749 26.3854 95.7751 26.4299C95.5747 26.4743 95.3973 26.4299 95.2192 26.3187Z"
                fill="black"
              />
              <path
                d="M96.8488 36.3451C96.4262 35.9671 96.1371 35.5447 95.9813 35.0778C95.8256 34.611 95.8256 34.1219 95.959 33.6105C96.0924 33.0991 96.3592 32.61 96.7817 32.1432C97.2043 31.6763 97.6492 31.3205 98.1381 31.1204C98.627 30.9203 99.116 30.8536 99.6055 30.9426C100.094 31.0315 100.562 31.2538 100.984 31.6318C101.406 32.0098 101.695 32.4322 101.851 32.899C102.007 33.3659 102.007 33.8773 101.851 34.3664C101.695 34.8555 101.451 35.3668 101.028 35.8337C100.606 36.3006 100.161 36.6341 99.6942 36.8342C99.2053 37.0343 98.7164 37.101 98.2268 37.012C97.7379 36.9231 97.2707 36.7008 96.8488 36.3229V36.3451ZM97.4934 35.6114C97.7602 35.856 98.0711 35.9894 98.3826 35.9894C98.6941 35.9894 99.0049 35.9227 99.294 35.767C99.6055 35.5892 99.8723 35.3668 100.139 35.0778C100.384 34.7888 100.583 34.4776 100.717 34.1663C100.85 33.8551 100.873 33.5215 100.828 33.2325C100.784 32.9213 100.606 32.6545 100.339 32.4099C100.05 32.1654 99.7613 32.032 99.4498 32.032C99.1383 32.032 98.8275 32.0987 98.516 32.2766C98.2045 32.4544 97.9377 32.6767 97.6709 32.9657C97.4041 33.2547 97.2266 33.566 97.0932 33.8773C96.9598 34.1886 96.9375 34.522 96.9822 34.811C97.0262 35.1223 97.2043 35.3891 97.4934 35.6336V35.6114Z"
                fill="black"
              />
              <path
                d="M104.785 39.1911L101.916 41.3699L101.205 40.4362L106.029 36.7678L106.719 37.6794L105.941 38.2796L105.985 38.3463C106.318 38.2574 106.652 38.2796 107.008 38.3908C107.341 38.5019 107.652 38.7465 107.919 39.1022C108.164 39.4357 108.319 39.7692 108.364 40.1249C108.408 40.4806 108.364 40.8141 108.186 41.1698C108.008 41.5033 107.741 41.8368 107.341 42.1481L104.273 44.4825L103.562 43.5487L106.519 41.3032C106.874 41.0364 107.074 40.7474 107.141 40.4139C107.208 40.0805 107.119 39.747 106.874 39.4357C106.719 39.2134 106.519 39.0577 106.296 38.9688C106.074 38.8799 105.829 38.8354 105.585 38.8799C105.318 38.9243 105.074 39.0355 104.829 39.2356L104.785 39.1911Z"
                fill="black"
              />
              <path
                d="M108.248 51.93L115.918 49.3733L116.852 52.1746C117.03 52.7082 117.097 53.1973 117.03 53.6197C116.963 54.0421 116.83 54.3756 116.563 54.6646C116.319 54.9314 116.008 55.1538 115.629 55.2649C115.318 55.3761 115.029 55.3983 114.763 55.3539C114.496 55.3094 114.274 55.2205 114.073 55.0648C113.873 54.9092 113.718 54.7313 113.562 54.509H113.496C113.562 54.798 113.562 55.0648 113.496 55.3539C113.428 55.6429 113.273 55.9097 113.051 56.1764C112.829 56.421 112.517 56.6211 112.117 56.7545C111.717 56.8879 111.339 56.9101 110.961 56.8212C110.583 56.7322 110.249 56.5321 109.938 56.2209C109.627 55.9097 109.382 55.4428 109.182 54.8425L108.204 51.9078L108.248 51.93ZM109.627 52.7526L110.183 54.42C110.36 54.9759 110.605 55.3316 110.894 55.5095C111.183 55.6873 111.494 55.7096 111.828 55.5984C112.072 55.5095 112.273 55.3761 112.428 55.1982C112.584 54.9981 112.695 54.7758 112.739 54.509C112.784 54.2422 112.739 53.9532 112.65 53.6419L112.072 51.93L109.605 52.7526H109.627ZM112.984 51.641L113.496 53.1973C113.584 53.4641 113.718 53.6642 113.873 53.842C114.051 54.0199 114.229 54.131 114.451 54.1977C114.674 54.2644 114.918 54.2422 115.163 54.1755C115.474 54.0643 115.719 53.8643 115.852 53.5753C115.985 53.2862 115.985 52.9083 115.852 52.4413L115.318 50.8629L112.984 51.641Z"
                fill="black"
              />
              <path
                d="M110.896 63.2245C110.829 62.6243 110.896 62.1129 111.118 61.646C111.319 61.1791 111.652 60.8234 112.097 60.5344C112.541 60.2454 113.075 60.0676 113.698 60.0009C114.32 59.9342 114.876 60.0009 115.365 60.1565C115.854 60.3343 116.254 60.6234 116.566 61.0013C116.876 61.3792 117.054 61.8684 117.121 62.4242C117.166 62.7577 117.121 63.0911 117.054 63.4245C116.966 63.7583 116.832 64.0692 116.61 64.336C116.387 64.6251 116.098 64.8472 115.743 65.0477C115.387 65.2475 114.92 65.3592 114.365 65.4255L113.942 65.4702L113.409 60.69L114.275 60.6011L114.676 64.2473C114.987 64.2026 115.254 64.1139 115.476 63.9581C115.698 63.8024 115.876 63.6026 115.987 63.3579C116.098 63.1134 116.143 62.8466 116.098 62.5354C116.054 62.2019 115.943 61.9351 115.765 61.6905C115.587 61.4682 115.343 61.2903 115.076 61.2014C114.809 61.0902 114.52 61.068 114.209 61.0902L113.52 61.1569C113.119 61.2014 112.786 61.3125 112.519 61.4904C112.253 61.6683 112.052 61.8906 111.941 62.1574C111.83 62.4242 111.785 62.7354 111.83 63.0911C111.852 63.3135 111.919 63.5139 111.986 63.6913C112.052 63.8694 112.186 64.0251 112.319 64.1362C112.452 64.2473 112.63 64.336 112.83 64.3807L112.764 65.5143C112.43 65.4702 112.119 65.3368 111.852 65.1364C111.586 64.9366 111.363 64.6698 111.185 64.336C111.007 64.0028 110.896 63.6249 110.852 63.1801L110.896 63.2245Z"
                fill="black"
              />
              <path
                d="M118.728 69.9605L118.661 71.1394L112.925 70.7833C112.703 70.7833 112.525 70.7833 112.414 70.8503C112.302 70.9167 112.214 70.9837 112.169 71.0948C112.125 71.2058 112.103 71.3169 112.08 71.4503C112.08 71.5397 112.08 71.6284 112.08 71.6948C112.08 71.7618 112.08 71.8282 112.08 71.8728L111.102 72.0286C111.102 72.0286 111.058 71.8505 111.035 71.7394C111.013 71.6284 111.013 71.4503 111.035 71.2728C111.035 70.9614 111.124 70.6722 111.257 70.4054C111.391 70.1386 111.591 69.9388 111.836 69.7831C112.08 69.6273 112.392 69.561 112.77 69.5827L118.728 69.9605ZM117.127 72.2954L116.193 72.2284L116.393 68.9156L117.349 68.9827L117.149 72.2954H117.127Z"
                fill="black"
              />
              <path
                d="M110.14 76.7865C110.229 76.4086 110.385 76.0971 110.585 75.8303C110.785 75.5635 111.052 75.3631 111.363 75.252C111.674 75.141 112.008 75.1186 112.408 75.2297C112.741 75.3191 113.008 75.4301 113.186 75.6082C113.364 75.7857 113.497 76.0084 113.564 76.2529C113.631 76.4973 113.675 76.7641 113.675 77.0533C113.675 77.3418 113.675 77.6309 113.631 77.9201C113.587 78.2756 113.564 78.5871 113.52 78.8092C113.475 79.032 113.52 79.2094 113.52 79.3205C113.564 79.4322 113.653 79.4986 113.786 79.5433H113.809C114.142 79.632 114.431 79.5873 114.654 79.4539C114.876 79.3205 115.032 79.0537 115.12 78.6758C115.209 78.298 115.209 77.9648 115.076 77.698C114.943 77.4312 114.81 77.2307 114.609 77.0973L115.12 76.0748C115.454 76.2969 115.699 76.542 115.854 76.8529C116.009 77.1644 116.121 77.4752 116.143 77.8314C116.165 78.1869 116.143 78.5207 116.054 78.8762C115.988 79.1207 115.921 79.3652 115.787 79.6096C115.676 79.8541 115.521 80.0769 115.321 80.2766C115.12 80.4771 114.876 80.6105 114.564 80.6992C114.254 80.7879 113.897 80.7662 113.453 80.6768L109.54 79.743L109.807 78.6318L110.607 78.8316V78.7875C110.496 78.6758 110.363 78.5424 110.251 78.3426C110.14 78.1645 110.073 77.9424 110.029 77.6756C109.985 77.4088 110.029 77.1197 110.096 76.8088L110.14 76.7865ZM111.007 77.2531C110.941 77.5646 110.941 77.8537 111.007 78.1205C111.074 78.3873 111.208 78.5871 111.385 78.7652C111.563 78.9426 111.764 79.0537 112.008 79.0984L112.764 79.2764C112.764 79.2764 112.719 79.143 112.697 79.0096C112.675 78.8762 112.697 78.7428 112.697 78.5871C112.697 78.4314 112.719 78.2756 112.741 78.1422C112.741 77.9871 112.764 77.8754 112.786 77.7867C112.808 77.5646 112.808 77.3418 112.786 77.142C112.764 76.9422 112.697 76.7641 112.608 76.6307C112.519 76.4973 112.364 76.4086 112.164 76.3416C111.875 76.2752 111.63 76.3416 111.452 76.4973C111.252 76.6754 111.119 76.9199 111.052 77.2531H111.007Z"
                fill="black"
              />
              <path
                d="M78.7305 116.76L75.5959 110.558H75.5072L74.1955 117.405L72.8838 117.583L74.6397 109.179L76.0408 108.979L80.0428 116.582L78.7305 116.76Z"
                fill="black"
              />
              <path
                d="M66.8129 109.29C67.4136 109.334 67.9248 109.49 68.325 109.757C68.7252 110.023 69.0584 110.424 69.2589 110.891C69.4586 111.38 69.5474 111.936 69.5033 112.558C69.4586 113.18 69.3252 113.714 69.0584 114.181C68.7916 114.648 68.4584 114.982 68.0136 115.226C67.5687 115.471 67.0797 115.559 66.5238 115.537C66.1906 115.537 65.8568 115.448 65.5459 115.315C65.2344 115.181 64.9453 114.982 64.7232 114.737C64.4787 114.47 64.3006 114.159 64.1672 113.759C64.034 113.358 63.9895 112.892 64.034 112.336V111.913L68.881 112.203L68.8363 113.092L65.1904 112.87C65.1904 113.18 65.2121 113.47 65.3238 113.714C65.4349 113.958 65.59 114.159 65.8127 114.315C66.0349 114.47 66.3017 114.559 66.5908 114.581C66.924 114.581 67.2131 114.537 67.4799 114.381C67.7468 114.225 67.9472 114.025 68.0806 113.781C68.214 113.537 68.3027 113.247 68.325 112.958L68.3691 112.269C68.3914 111.869 68.3474 111.513 68.214 111.224C68.0806 110.935 67.9025 110.713 67.658 110.535C67.4136 110.38 67.1021 110.268 66.7689 110.268C66.5461 110.268 66.3463 110.268 66.1459 110.335C65.9685 110.38 65.7904 110.468 65.657 110.602C65.5236 110.735 65.4125 110.869 65.3238 111.068L64.2119 110.802C64.323 110.468 64.5011 110.202 64.7455 109.979C64.99 109.757 65.2791 109.579 65.6347 109.467C65.9902 109.356 66.3904 109.312 66.8353 109.334L66.8129 109.29Z"
                fill="black"
              />
              <path
                d="M60.9849 108.645L59.5621 114.537L58.4504 114.27L58.6728 113.336H58.6061C58.4282 113.603 58.1614 113.826 57.8502 113.937C57.5389 114.048 57.2054 114.07 56.8497 113.981C56.783 113.981 56.6941 113.937 56.6051 113.914C56.5162 113.892 56.4273 113.87 56.3828 113.848L56.6496 112.758C56.6496 112.758 56.7608 112.825 56.8941 112.869C57.0053 112.914 57.1165 112.958 57.2499 112.98C57.5167 113.047 57.7835 113.047 58.0058 112.98C58.2504 112.914 58.4504 112.803 58.6283 112.625C58.8061 112.447 58.9173 112.247 58.984 111.98L59.8511 108.378L61.0072 108.645H60.9849Z"
                fill="black"
              />
              <path
                d="M49.0217 109.379L50.0888 109.624C50.0888 109.757 50.0888 109.913 50.1332 110.068C50.1777 110.224 50.2444 110.357 50.3778 110.491C50.489 110.624 50.6669 110.735 50.8892 110.846C51.2004 110.98 51.4894 111.024 51.734 110.98C52.0008 110.935 52.1786 110.802 52.2676 110.579C52.3565 110.402 52.3343 110.224 52.2676 110.046C52.1786 109.868 52.0008 109.69 51.7118 109.49L50.9114 108.912C50.4445 108.579 50.1555 108.223 49.9998 107.867C49.8442 107.512 49.8664 107.134 50.0443 106.734C50.1777 106.4 50.4223 106.133 50.7113 105.977C51.0004 105.822 51.3783 105.71 51.7785 105.71C52.1786 105.71 52.6233 105.8 53.068 106C53.6905 106.266 54.1351 106.622 54.4241 107.067C54.6909 107.512 54.7799 107.978 54.6687 108.512L53.5571 108.223C53.6015 107.912 53.5571 107.667 53.4237 107.422C53.2903 107.2 53.0457 107.022 52.7122 106.867C52.3565 106.711 52.0452 106.667 51.7562 106.734C51.4894 106.8 51.2894 106.933 51.2004 107.134C51.1337 107.311 51.1337 107.489 51.2004 107.645C51.2894 107.823 51.445 108.001 51.6673 108.179L52.5121 108.801C52.9791 109.134 53.2903 109.49 53.4237 109.868C53.5571 110.224 53.5348 110.624 53.3792 111.002C53.2458 111.336 53.0235 111.58 52.7345 111.736C52.4454 111.913 52.1119 111.98 51.7118 111.98C51.3338 111.98 50.9337 111.892 50.5112 111.714C49.9109 111.447 49.4885 111.113 49.2662 110.713C49.0439 110.312 48.955 109.868 49.0217 109.379Z"
                fill="black"
              />
              <path
                d="M43.7764 109.157C43.9543 109.268 44.0654 109.423 44.1099 109.601C44.1544 109.801 44.1321 109.979 44.0432 110.135C43.9321 110.291 43.7987 110.402 43.5986 110.447C43.3984 110.491 43.2206 110.447 43.0427 110.335C42.8648 110.224 42.7537 110.068 42.7092 109.891C42.6648 109.69 42.687 109.513 42.7759 109.357C42.8871 109.201 43.0205 109.09 43.2206 109.046C43.4207 109.001 43.5985 109.046 43.7764 109.157ZM47.9784 103.532L44.7768 108.69L43.7764 108.068L46.9779 102.91L47.9784 103.532Z"
                fill="black"
              />
              <path
                d="M42.1537 99.1303C42.5761 99.5082 42.8651 99.9307 43.0208 100.397C43.1764 100.864 43.1764 101.353 43.043 101.865C42.9096 102.376 42.6428 102.865 42.2204 103.332C41.798 103.799 41.3533 104.155 40.8642 104.355C40.3751 104.554 39.886 104.622 39.3969 104.533C38.9078 104.443 38.4409 104.221 38.0184 103.843C37.596 103.466 37.307 103.043 37.1514 102.576C36.9958 102.109 36.9958 101.598 37.1514 101.109C37.307 100.619 37.5515 100.108 37.9739 99.6416C38.3964 99.1744 38.8411 98.8412 39.3079 98.6407C39.797 98.441 40.2861 98.3739 40.7753 98.4633C41.2644 98.552 41.7313 98.7741 42.1537 99.152V99.1303ZM41.5089 99.8637C41.2422 99.6192 40.9309 99.4858 40.6196 99.4858C40.3084 99.4858 39.9971 99.5522 39.7081 99.708C39.3969 99.886 39.1301 100.108 38.8633 100.397C38.6187 100.686 38.4186 100.997 38.2852 101.309C38.1518 101.62 38.1296 101.953 38.174 102.243C38.2185 102.554 38.3964 102.821 38.6632 103.065C38.9522 103.31 39.2412 103.443 39.5525 103.443C39.8637 103.443 40.175 103.376 40.4862 103.199C40.7975 103.021 41.0643 102.799 41.3311 102.509C41.5979 102.22 41.7757 101.909 41.9091 101.598C42.0425 101.286 42.0647 100.953 42.0203 100.664C41.9758 100.353 41.798 100.086 41.5089 99.8414V99.8637Z"
                fill="black"
              />
              <path
                d="M34.22 96.2619L37.088 94.0827L37.7994 95.0166L32.975 98.6848L32.2857 97.7733L33.0639 97.1733L33.0194 97.1063C32.686 97.1957 32.3524 97.1733 31.9967 97.0623C31.6632 96.9512 31.352 96.7061 31.0852 96.3506C30.8407 96.0174 30.685 95.6836 30.6406 95.328C30.5961 94.9725 30.6406 94.6387 30.8184 94.2832C30.9963 93.9494 31.2631 93.6162 31.6632 93.3047L34.7313 90.9705L35.4428 91.9043L32.4858 94.1498C32.1301 94.4166 31.93 94.7057 31.8633 95.0389C31.7966 95.3727 31.8855 95.7059 32.1301 96.0174C32.2857 96.2395 32.4859 96.3953 32.7082 96.484C32.9305 96.5727 33.1751 96.6174 33.4196 96.5727C33.6864 96.5287 33.9309 96.4176 34.1755 96.2172L34.22 96.2619Z"
                fill="black"
              />
              <path
                d="M30.7298 83.5006L23.0596 86.0346L22.1258 83.2338C21.948 82.6779 21.8813 82.2106 21.948 81.7887C22.0147 81.3662 22.1703 81.0324 22.4148 80.7438C22.6594 80.477 22.9706 80.2543 23.3486 80.1432C23.6599 80.0322 23.9489 80.0098 24.2157 80.0545C24.4825 80.0985 24.7048 80.1879 24.9049 80.3436C25.105 80.4987 25.2606 80.6768 25.4162 80.899H25.4829C25.4162 80.6104 25.4162 80.3436 25.4829 80.0545C25.5496 79.7654 25.7053 79.4985 25.9276 79.2317C26.1499 78.9873 26.4612 78.7869 26.8613 78.6535C27.2615 78.5201 27.6395 78.4984 28.0175 78.5871C28.3954 78.6758 28.7289 78.8762 29.0401 79.1871C29.3514 79.5209 29.5959 79.9651 29.796 80.5658L30.7743 83.5006H30.7298ZM25.9943 83.7891L25.4829 82.233C25.394 81.9662 25.2606 81.7664 25.105 81.5883C24.9271 81.4102 24.7492 81.2992 24.5269 81.2328C24.3046 81.1658 24.0601 81.1881 23.8155 81.2551C23.5043 81.3662 23.2597 81.566 23.1263 81.8551C22.9929 82.1442 22.9929 82.5221 23.1263 82.9887L23.6599 84.5678L25.9943 83.7891ZM29.3514 82.6779L28.7956 81.01C28.6177 80.4547 28.3732 80.0985 28.0842 79.9211C27.7951 79.743 27.4839 79.7207 27.1503 79.8317C26.9058 79.9211 26.7057 80.0545 26.5501 80.2319C26.3945 80.41 26.2833 80.6545 26.2389 80.9213C26.1944 81.1881 26.2389 81.4772 26.3278 81.7887L26.8836 83.5006L29.3514 82.6779Z"
                fill="black"
              />
              <path
                d="M28.1058 72.2507C28.1725 72.8507 28.1058 73.3626 27.8835 73.8292C27.6834 74.2958 27.3499 74.652 26.9052 74.9411C26.4606 75.2296 25.927 75.4077 25.3045 75.4747C24.682 75.5411 24.1262 75.4747 23.6371 75.319C23.1479 75.1409 22.7478 74.8518 22.4365 74.4739C22.1253 74.096 21.9474 73.6071 21.8807 73.0512C21.8362 72.7173 21.8807 72.3841 21.9474 72.051C22.0364 71.7171 22.1697 71.4057 22.3921 71.1389C22.6144 70.8503 22.9034 70.6276 23.2591 70.4278C23.6371 70.2274 24.0817 70.1163 24.6375 70.0499L25.0599 70.0053L25.5935 74.7854L24.7265 74.8741L24.3263 71.2282C24.015 71.2723 23.7482 71.3616 23.5259 71.5174C23.3035 71.6725 23.1257 71.8729 23.0145 72.1173C22.9034 72.3618 22.8589 72.6286 22.9034 72.9401C22.9478 73.2733 23.059 73.5401 23.2368 73.7846C23.4147 74.0073 23.6593 74.1848 23.9261 74.2741C24.1929 74.3852 24.4819 74.4075 24.7932 74.3852L25.4823 74.3182C25.8825 74.2741 26.2161 74.1624 26.4828 73.985C26.7496 73.8069 26.9497 73.5848 27.0609 73.318C27.172 73.0512 27.2165 72.7397 27.172 72.3841C27.1498 72.162 27.0831 71.9616 27.0164 71.7842C26.9497 71.6061 26.8163 71.4503 26.6829 71.3393C26.5495 71.2282 26.3717 71.1389 26.1716 71.0948L26.2383 69.9606C26.5718 70.0053 26.883 70.1387 27.1498 70.3384C27.4166 70.5389 27.6389 70.8057 27.8168 71.1389C27.9946 71.4727 28.1058 71.8505 28.1503 72.2954L28.1058 72.2507Z"
                fill="black"
              />
              <path
                d="M20.282 65.5142L20.3487 64.3359L26.0847 64.6914C26.307 64.6914 26.4849 64.6914 26.5961 64.625C26.7072 64.558 26.7961 64.4916 26.8406 64.3799C26.8851 64.2689 26.9073 64.1578 26.9295 64.0244C26.9295 63.9357 26.9295 63.847 26.9295 63.7799C26.9295 63.7136 26.9295 63.6465 26.9295 63.6019L27.9077 63.4467C27.9077 63.4467 27.9522 63.6242 27.9744 63.7353C27.9967 63.847 27.9967 64.0244 27.9744 64.2025C27.9744 64.5133 27.8855 64.8025 27.7521 65.0693C27.6187 65.3361 27.4186 65.5365 27.1741 65.6922C26.9295 65.8473 26.6183 65.9144 26.2403 65.892L20.282 65.5142ZM21.8827 63.1797L22.8165 63.2464L22.6164 66.559L21.6604 66.492L21.8605 63.1797H21.8827Z"
                fill="black"
              />
              <path
                d="M28.8636 58.6666C28.7747 59.0445 28.619 59.3558 28.4189 59.6226C28.2188 59.8893 27.952 60.0894 27.6408 60.2006C27.3296 60.3117 26.9961 60.334 26.5959 60.2228C26.2624 60.1339 25.9956 60.0227 25.8177 59.8449C25.6399 59.667 25.5065 59.4447 25.4398 59.2002C25.3731 58.9556 25.3286 58.6888 25.3286 58.3997C25.3286 58.1107 25.3286 57.8217 25.3731 57.5327C25.4175 57.177 25.4398 56.8658 25.4842 56.6434C25.5287 56.4211 25.4842 56.2432 25.4842 56.132C25.4398 56.0209 25.3509 55.9542 25.2175 55.9097H25.1952C24.8618 55.8208 24.5727 55.8653 24.3504 55.9986C24.128 56.132 23.9724 56.3988 23.8835 56.7768C23.7946 57.1548 23.7946 57.4882 23.9279 57.755C24.0614 58.0218 24.1948 58.2219 24.3949 58.3553L23.8835 59.378C23.55 59.1557 23.3055 58.9111 23.1498 58.5999C22.9942 58.2886 22.8831 57.9773 22.8608 57.6216C22.8386 57.2659 22.8608 56.9325 22.9498 56.5767C23.0165 56.3321 23.0831 56.0876 23.2165 55.843C23.3277 55.5985 23.4833 55.3762 23.6834 55.1761C23.8835 54.976 24.128 54.8426 24.4393 54.7537C24.7506 54.6647 25.1063 54.687 25.5509 54.7759L29.4639 55.7096L29.1971 56.8213L28.3967 56.6212V56.6656C28.5079 56.7768 28.6413 56.9102 28.7524 57.1103C28.8636 57.2882 28.9303 57.5105 28.9748 57.7773C29.0192 58.044 28.9748 58.333 28.9081 58.6443L28.8636 58.6666ZM27.9965 58.1997C28.0632 57.8884 28.0632 57.5994 27.9965 57.3326C27.9298 57.0659 27.7964 56.8658 27.6186 56.6879C27.4407 56.51 27.2406 56.3988 26.9961 56.3544L26.2401 56.1765C26.2401 56.1765 26.2846 56.3099 26.3068 56.4433C26.3291 56.5767 26.3068 56.7101 26.3068 56.8658C26.3068 57.0214 26.2846 57.177 26.2624 57.3104C26.2624 57.466 26.2401 57.5772 26.2179 57.6661C26.1957 57.8884 26.1957 58.1107 26.2179 58.3108C26.2401 58.5109 26.3068 58.6888 26.3958 58.8222C26.4847 58.9556 26.6404 59.0445 26.8405 59.1112C27.1295 59.1779 27.374 59.1112 27.5519 58.9556C27.752 58.7777 27.8853 58.5331 27.952 58.1997H27.9965Z"
                fill="black"
              />
            </svg>
          </svg>
        </div>
        {/* <button type="button">
          <div
            className="mx-[4vw] border-solid border
              border-[#B4C7C9] rounded-3xl flex flex-row py-3 px-5">
                <p
                  className="line-clamp-1 text-[#B4C7C9] uppercase font-neueLight
             text-left">
                  Shopping bio
                </p>
                <div className="flex items-center pl-2">
                  <svg width="18" height="10" viewBox="0 0 18 10" fill="none">
                    <path d="M17.1924 1.19239L9.19238 9.19239L1.19238 1.19239" stroke="#B4C7C9" />
                  </svg>
                </div>
              </div>
            </button>
          )} */}
        {/* {!haveSignIn && ( */}
        <div className="flex flex-col items-center mt-[3vw] mb-[3vw] mx-[5vw]">
          <p className="text-[#FD8851] font-neueLight text-2xl pb-2">Séduit par Quipo ?</p>
          <p className="text-white font-neueLight pb-2">Contactez-nous!</p>
          <div className="flex flex-col lg:flex lg:flex-row">
            <button
              type="button"
              className="relative mx-2 hover:cursor-pointer"
              onClick={handleClickWhatsApp}>
              <svg
                className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10"
                width="44"
                height="44"
                viewBox="0 0 44 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M22.0013 44H21.9911C18.0436 44 14.1701 42.9384 10.7931 40.935L9.99086 40.4589L1.66302 42.6431L3.88633 34.5274L3.36425 33.695C1.16132 30.1921 -0.00254257 26.1444 4.17059e-06 21.9898C0.00509765 9.86462 9.87371 0 22.0089 0C27.8842 0 33.4081 2.29114 37.5618 6.44828C41.7156 10.6054 44.0025 16.1296 44 22.0076C43.9949 34.1328 34.1263 44 21.9987 44H22.0013ZM34.0703 27.5293C33.4081 27.1983 30.1585 25.5996 29.5524 25.3782C28.9462 25.1567 28.5056 25.0472 28.0651 25.7091C27.6245 26.371 26.3562 27.8602 25.9716 28.3006C25.5871 28.741 25.2 28.797 24.5378 28.4661C23.8757 28.1352 21.7466 27.4376 19.2202 25.1847C17.2542 23.4307 15.9273 21.2668 15.5402 20.605C15.1556 19.9431 15.4995 19.5841 15.8305 19.2557C16.1285 18.9604 16.4927 18.4844 16.8238 18.0974C17.1548 17.7105 17.2643 17.4355 17.4859 16.9951C17.7075 16.5547 17.5954 16.1678 17.4299 15.8368C17.2643 15.5059 15.9426 12.2525 15.3899 10.9287C14.8526 9.64059 14.3076 9.8137 13.9026 9.79334C13.5181 9.77552 13.075 9.77043 12.6344 9.77043C12.1938 9.77043 11.4782 9.9359 10.8695 10.5978C10.2634 11.2597 8.5545 12.8584 8.5545 16.1143C8.5545 19.3703 10.9255 22.5117 11.254 22.9546C11.5851 23.395 15.9171 30.0724 22.5514 32.9364C24.1303 33.6186 25.3604 34.0234 26.3231 34.3289C27.9072 34.8329 29.3486 34.7616 30.4896 34.5911C31.7604 34.4001 34.4039 32.9924 34.954 31.4471C35.5041 29.9019 35.5041 28.5781 35.3385 28.3032C35.173 28.0282 34.7324 27.8628 34.0703 27.5318V27.5293Z"
                  fill="#51E5FD"
                />
              </svg>
              {modalWhatsApp && <ModalWhatsApp />}
              <svg
                className="relative"
                width="96"
                height="96"
                viewBox="0 0 96 96"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="48" cy="48" r="47.5" stroke="#B4C7C9" />
              </svg>
            </button>
            <button
              type="button"
              className="relative mx-2 hover:cursor-pointer"
              onClick={handleClickMail}>
              <svg
                className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10"
                width="41"
                height="28"
                viewBox="0 0 41 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.83885 0.521301H36.3457C38.1778 0.521301 39.6631 2.00653 39.6631 3.83866V23.9666C39.6631 25.7987 38.1778 27.284 36.3457 27.284H3.83885C2.00672 27.284 0.521484 25.7987 0.521484 23.9666V3.83866C0.521484 2.00653 2.00672 0.521301 3.83885 0.521301Z"
                  fill="#51E5FD"
                  stroke="#222222"
                />
                <mask id="path-2-inside-1_6685_36800" fill="white">
                  <path d="M36.3456 0.0426521C38.4385 0.0426521 40.1416 1.74575 40.1416 3.83869V23.9666C40.1416 26.0596 38.4385 27.7627 36.3456 27.7627H3.83869C1.74575 27.7627 0.0426521 26.0596 0.0426521 23.9666V3.83869C0.0426521 1.74575 1.74575 0.0426521 3.83869 0.0426521H36.3456ZM36.3456 0H3.83869C1.71845 0 0 1.71845 0 3.83869V23.9666C0 26.0869 1.71845 27.8053 3.83869 27.8053H36.3456C38.4658 27.8053 40.1842 26.0869 40.1842 23.9666V3.83869C40.1842 1.71845 38.4658 0 36.3456 0Z" />
                </mask>
                <path
                  d="M36.3456 0.0426521C38.4385 0.0426521 40.1416 1.74575 40.1416 3.83869V23.9666C40.1416 26.0596 38.4385 27.7627 36.3456 27.7627H3.83869C1.74575 27.7627 0.0426521 26.0596 0.0426521 23.9666V3.83869C0.0426521 1.74575 1.74575 0.0426521 3.83869 0.0426521H36.3456ZM36.3456 0H3.83869C1.71845 0 0 1.71845 0 3.83869V23.9666C0 26.0869 1.71845 27.8053 3.83869 27.8053H36.3456C38.4658 27.8053 40.1842 26.0869 40.1842 23.9666V3.83869C40.1842 1.71845 38.4658 0 36.3456 0Z"
                  fill="#51E5FD"
                />
                <path
                  d="M36.3456 1.04265C37.8862 1.04265 39.1416 2.29803 39.1416 3.83869H41.1416C41.1416 1.19347 38.9908 -0.957348 36.3456 -0.957348V1.04265ZM39.1416 3.83869V23.9666H41.1416V3.83869H39.1416ZM39.1416 23.9666C39.1416 25.5073 37.8862 26.7627 36.3456 26.7627V28.7627C38.9908 28.7627 41.1416 26.6119 41.1416 23.9666H39.1416ZM36.3456 26.7627H3.83869V28.7627H36.3456V26.7627ZM3.83869 26.7627C2.29803 26.7627 1.04265 25.5073 1.04265 23.9666H-0.957348C-0.957348 26.6119 1.19347 28.7627 3.83869 28.7627V26.7627ZM1.04265 23.9666V3.83869H-0.957348V23.9666H1.04265ZM1.04265 3.83869C1.04265 2.29803 2.29803 1.04265 3.83869 1.04265V-0.957348C1.19347 -0.957348 -0.957348 1.19347 -0.957348 3.83869H1.04265ZM3.83869 1.04265H36.3456V-0.957348H3.83869V1.04265ZM36.3456 -1H3.83869V1H36.3456V-1ZM3.83869 -1C1.16617 -1 -1 1.16617 -1 3.83869H1C1 2.27074 2.27074 1 3.83869 1V-1ZM-1 3.83869V23.9666H1V3.83869H-1ZM-1 23.9666C-1 26.6392 1.16617 28.8053 3.83869 28.8053V26.8053C2.27074 26.8053 1 25.5346 1 23.9666H-1ZM3.83869 28.8053H36.3456V26.8053H3.83869V28.8053ZM36.3456 28.8053C39.0181 28.8053 41.1842 26.6392 41.1842 23.9666H39.1842C39.1842 25.5346 37.9135 26.8053 36.3456 26.8053V28.8053ZM41.1842 23.9666V3.83869H39.1842V23.9666H41.1842ZM41.1842 3.83869C41.1842 1.16617 39.0181 -1 36.3456 -1V1C37.9135 1 39.1842 2.27074 39.1842 3.83869H41.1842Z"
                  fill="#222222"
                  mask="url(#path-2-inside-1_6685_36800)"
                />
                <path
                  d="M3.83867 27.2836H3.78512C3.15809 27.2736 2.55544 27.0888 2.03743 26.7528L20.0921 11.3547L38.1466 26.7522C37.6136 27.0978 36.9916 27.2836 36.3455 27.2836H3.83867Z"
                  fill="#51E5FD"
                  stroke="#222222"
                />
                <mask id="path-5-inside-2_6685_36800" fill="white">
                  <path d="M20.0921 10.7257L38.9047 26.7697C38.2035 27.4104 37.2967 27.7623 36.3455 27.7623H3.83868C2.88754 27.7623 1.98075 27.41 1.27955 26.7697L20.0921 10.7257ZM20.0921 10.6699L1.21558 26.7685C1.90185 27.4112 2.82441 27.8049 3.83868 27.8049H36.3455C37.3602 27.8049 38.2824 27.4112 38.9686 26.7685L20.0921 10.6699Z" />
                </mask>
                <path
                  d="M20.0921 10.7257L38.9047 26.7697C38.2035 27.4104 37.2967 27.7623 36.3455 27.7623H3.83868C2.88754 27.7623 1.98075 27.41 1.27955 26.7697L20.0921 10.7257ZM20.0921 10.6699L1.21558 26.7685C1.90185 27.4112 2.82441 27.8049 3.83868 27.8049H36.3455C37.3602 27.8049 38.2824 27.4112 38.9686 26.7685L20.0921 10.6699Z"
                  fill="#51E5FD"
                />
                <path
                  d="M20.0921 10.7257L20.741 9.96486L20.0921 9.41146L19.4432 9.96486L20.0921 10.7257ZM38.9047 26.7697L39.5792 27.508L40.4155 26.7439L39.5536 26.0089L38.9047 26.7697ZM1.27955 26.7697L0.630654 26.0089L-0.231548 26.7442L0.605296 27.5082L1.27955 26.7697ZM20.0921 10.6699L20.741 9.90899L20.0921 9.35558L19.4432 9.90899L20.0921 10.6699ZM1.21558 26.7685L0.566676 26.0076L-0.284555 26.7336L0.531982 27.4983L1.21558 26.7685ZM38.9686 26.7685L39.6522 27.4983L40.4688 26.7336L39.6175 26.0076L38.9686 26.7685ZM19.4432 11.4866L38.2558 27.5306L39.5536 26.0089L20.741 9.96486L19.4432 11.4866ZM38.2302 26.0315C37.7134 26.5036 37.0472 26.7623 36.3455 26.7623V28.7623C37.5461 28.7623 38.6936 28.3171 39.5792 27.508L38.2302 26.0315ZM36.3455 26.7623H3.83868V28.7623H36.3455V26.7623ZM3.83868 26.7623C3.13726 26.7623 2.47091 26.5034 1.95381 26.0312L0.605296 27.5082C1.4906 28.3165 2.63782 28.7623 3.83868 28.7623V26.7623ZM1.92845 27.5306L20.741 11.4866L19.4432 9.96486L0.630654 26.0089L1.92845 27.5306ZM19.4432 9.90899L0.566676 26.0076L1.86448 27.5293L20.741 11.4307L19.4432 9.90899ZM0.531982 27.4983C1.39612 28.3077 2.5605 28.8049 3.83868 28.8049V26.8049C3.08832 26.8049 2.40758 26.5148 1.89917 26.0386L0.531982 27.4983ZM3.83868 28.8049H36.3455V26.8049H3.83868V28.8049ZM36.3455 28.8049C37.6242 28.8049 38.7881 28.3076 39.6522 27.4983L38.2851 26.0386C37.7766 26.5148 37.0963 26.8049 36.3455 26.8049V28.8049ZM39.6175 26.0076L20.741 9.90899L19.4432 11.4307L38.3197 27.5293L39.6175 26.0076Z"
                  fill="#222222"
                  mask="url(#path-5-inside-2_6685_36800)"
                />
                <path
                  d="M20.0921 16.4506L2.03743 1.05249C2.57017 0.706922 3.19244 0.521301 3.83867 0.521301H36.3455C36.9918 0.521301 37.614 0.706922 38.1468 1.05249L20.0921 16.4506Z"
                  fill="#51E5FD"
                  stroke="#222222"
                />
                <mask id="path-8-inside-3_6685_36800" fill="white">
                  <path d="M36.3455 0.0426521C37.2967 0.0426521 38.2035 0.394958 38.9047 1.03517L20.0921 17.0792L1.27955 1.03517C1.98075 0.394958 2.88754 0.0426521 3.83868 0.0426521H36.3455ZM36.3455 0H3.83868C2.82399 0 1.90185 0.393679 1.21558 1.03645L20.0921 17.1355L38.9686 1.03645C38.2824 0.393679 37.3598 0 36.3455 0Z" />
                </mask>
                <path
                  d="M36.3455 0.0426521C37.2967 0.0426521 38.2035 0.394958 38.9047 1.03517L20.0921 17.0792L1.27955 1.03517C1.98075 0.394958 2.88754 0.0426521 3.83868 0.0426521H36.3455ZM36.3455 0H3.83868C2.82399 0 1.90185 0.393679 1.21558 1.03645L20.0921 17.1355L38.9686 1.03645C38.2824 0.393679 37.3598 0 36.3455 0Z"
                  fill="#51E5FD"
                />
                <path
                  d="M38.9047 1.03517L39.5536 1.79604L40.4158 1.06072L39.5789 0.296671L38.9047 1.03517ZM20.0921 17.0792L19.4432 17.84L20.0921 18.3935L20.741 17.84L20.0921 17.0792ZM1.27955 1.03517L0.605296 0.296671L-0.231548 1.06072L0.630654 1.79604L1.27955 1.03517ZM1.21558 1.03645L0.531982 0.306583L-0.284545 1.07135L0.566666 1.79731L1.21558 1.03645ZM20.0921 17.1355L19.4432 17.8963L20.0921 18.4498L20.741 17.8963L20.0921 17.1355ZM38.9686 1.03645L39.6176 1.79731L40.4688 1.07135L39.6522 0.306583L38.9686 1.03645ZM36.3455 1.04265C37.047 1.04265 37.7133 1.30154 38.2304 1.77366L39.5789 0.296671C38.6936 -0.511623 37.5464 -0.957348 36.3455 -0.957348V1.04265ZM38.2558 0.274292L19.4432 16.3183L20.741 17.84L39.5536 1.79604L38.2558 0.274292ZM20.741 16.3183L1.92845 0.274292L0.630654 1.79604L19.4432 17.84L20.741 16.3183ZM1.95381 1.77366C2.47091 1.30154 3.13726 1.04265 3.83868 1.04265V-0.957348C2.63782 -0.957348 1.4906 -0.511623 0.605296 0.296671L1.95381 1.77366ZM3.83868 1.04265H36.3455V-0.957348H3.83868V1.04265ZM36.3455 -1H3.83868V1H36.3455V-1ZM3.83868 -1C2.56002 -1 1.39608 -0.502734 0.531982 0.306583L1.89917 1.76631C2.40762 1.29009 3.08796 1 3.83868 1V-1ZM0.566666 1.79731L19.4432 17.8963L20.741 16.3746L1.86449 0.275581L0.566666 1.79731ZM20.741 17.8963L39.6176 1.79731L38.3197 0.275581L19.4432 16.3746L20.741 17.8963ZM39.6522 0.306583C38.7881 -0.502776 37.6237 -1 36.3455 -1V1C37.0959 1 37.7766 1.29013 38.2851 1.76631L39.6522 0.306583Z"
                  fill="#222222"
                  mask="url(#path-8-inside-3_6685_36800)"
                />
              </svg>
              {modalMail && <ModalMail />}
              <svg
                className="relative"
                width="96"
                height="96"
                viewBox="0 0 96 96"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="48" cy="48" r="47.5" stroke="#B4C7C9" />
              </svg>
            </button>
          </div>
          {/* <div className="mt-[3vw] mx-[5.5vw]">
            {links.map((link) => (
              <div key={link.name} className="pb-3">
                <Link to={link.url}>
                  <p className="text-left text-white uppercase font-neueLight text-sm hover:text-red-400">
                    {link.name}
                  </p>
                </Link>
              </div>
            ))}
          </div> */}
        </div>
        {/* )} */}
      </div>
    </div>
  );
}

export default Navbar;
