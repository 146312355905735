import React from 'react';

export default function MobileWarning() {
  return (
    <div className="bg-mainBg items-center mx-[4vw] my-4">
      <div className=" mt-[10vw] mb-[4vw] ">
        <img
          src={require('../../assets/images/logoQuipo.png')}
          id="logoQuipo"
          alt="Logo Quipo"
          className="mb-8"
        />
        <p className="font-neueUltra">
          {' '}
          Le QuipoBoard n'est actuellement pas accessible sur mobile, retrouvez-nous sur PC ou sur
          tablette!
        </p>{' '}
      </div>
    </div>
  );
}
