/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
/* eslint-disable no-alert */
import React from 'react';
import ModalErrMsg from './ModalErrMsg';
import { useCustomContext } from '../../contexts/customContext';

function MessageAlert() {
  const customContext = useCustomContext();
  const maxCharacters = 110;
  const { mainMessage } = customContext;

  return (
    <div className="w-full mt-16">
      <div>
        <div>
          <p className="pb-6 font-neueUltra font-bold">Messages aux clients</p>
          <p>Principal</p>
          <textarea
            className={`resize-none block h-[8vw] relative mt-4 bg-transparent w-full rounded-2xl py-4 text-xs box-border border-solid border-[1px] flex-column justify-center items-center font-inter border-black               placeholder:text-left pl-4 placeholder:text-black placeholder:font-bold placeholder:font-inter placeholder:absolute px-2 max-rows:6 ${
              mainMessage.length === maxCharacters && 'border-red-500'
            }`}
            rows={4}
            placeholder="MESSAGE (110 CARACTERES MAX)"
            defaultValue={mainMessage}
            maxLength={110}
            onChange={(event) => {
              if (event.target.value.length <= maxCharacters) {
                customContext.onChangeMainMessage(event.target.value);
              }
            }}
          />
          {mainMessage.length === maxCharacters && <ModalErrMsg />}
          <div className="mt-4 flex flex-row items-center justify-center space-x-1">
            <hr className="border-black rounded-xs border-2 w-[2.5vw] hover:border-redColor hover:cursor-pointer" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default MessageAlert;
