/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
import React, { useRef } from 'react';
import { useCustomContext } from '../../contexts/customContext';

export default function UploadResizeLogo() {
  const useContext = useCustomContext();
  const inputRef = useRef<HTMLInputElement>(null);

  function onImageChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files?.length !== 0 && event.target.files !== null) {
      const file = event.target.files[0];
      console.log('file', file);
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const image = new Image();

        if (image.src !== null && e.target !== null) {
          if (typeof e.target.result === 'string') {
            image.src = e.target.result;
            image.onload = () => {
              const { height, width } = image;
              useContext.onChangeLogo({
                file,
                logoHeightOrigin: height,
                logoWidthOrigin: width,
              });
            };
          }
        }
      };
    }
  }

  return (
    <div className="w-full">
      <div
        onClick={() => {
          if (inputRef.current !== null) {
            inputRef.current.click();
          }
        }}
        className="cursor-pointer flex font-inter bg-transparent rounded-2xl py-10 mt-8 text-sm text-center box-border border-dotted border-2 border-black flex-col justify-center items-center">
        <div>
          <div className="justify-center items-center">
            <button type="button">
              <img
                src={require('../../assets/images/rondFleche.png')}
                alt="Logo Import"
                id="rondFleche"
                className=" mx-auto pb-3"
              />
              <input
                ref={inputRef}
                id="selectedImage"
                type="file"
                className="hidden"
                accept="image/png"
                onChange={onImageChange}
              />
            </button>
          </div>
          <div className="text-sm">CHOISISSEZ VOTRE LOGO</div>
          <div className="text-xs text-gray-500">400 x 2000 px - Max 400kb</div>
        </div>
      </div>
    </div>
  );
}
