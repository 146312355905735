/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useContext } from 'react';
import { isNumberObject } from 'util/types';

export type LogoType = null | {
  file: File;
  logoWidthOrigin: number;
  logoHeightOrigin: number;
};

export type CustomContext = {
  shopId: number;
  siretNumber: string;
  nbOfArticles: number;
  shopName: string;
  address: string;
  phone: string;
  bgColor: string;
  textColor: string;
  firstMessage: string;
  mainMessage: string;
  secondaryMessage: string;
  logo: LogoType;
  shopAlert: string;
  discount: string;
  chooseMsg1: string;
  chooseMsg2: string;
  alertMsg1: string;
  alertMsg2: string;
  secondaryMsg1: string;
  secondaryMsg2: string;
  onChangeShopId: (shopId: number) => void;
  onChangeSiretNumber: (siretNumber: string) => void;
  onChangeNbOfArticles: (nbOfArticles: number) => void;
  onChangeShopName: (shopName: string) => void;
  onChangeAddress: (address: string) => void;
  onChangePhone: (address: string) => void;
  onChangeBgColor: (bgColor: string) => void;
  onChangeTextColor: (textColor: string) => void;
  onChangeFirstMessage: (firstMessage: string) => void;
  onChangeMainMessage: (message: string) => void;
  onChangeSecondaryMessage: (secondaryMessage: string) => void;
  onChangeLogo: (logo: LogoType) => void;
  onChangeShopAlert: (shopAlert: string) => void;
  onChangeDiscount: (discount: string) => void;
  onChangeChooseMsg1: (chooseMsg1: string) => void;
  onChangeChooseMsg2: (chooseMsg2: string) => void;
  onChangeAlertMsg1: (alertMsg1: string) => void;
  onChangeAlertMsg2: (alertMsg2: string) => void;
  onChangeSecondaryMsg1: (secondaryMsg1: string) => void;
  onChangeSecondaryMsg2: (secondaryMsg2: string) => void;
};

export const customContext = createContext<CustomContext>({
  shopId: 1,
  siretNumber: '987456',
  nbOfArticles: 3,
  shopName: '',
  address: '',
  phone: '',
  bgColor: 'white',
  textColor: '',
  firstMessage: '',
  mainMessage: '',
  secondaryMessage: '',
  logo: null,
  shopAlert: '',
  discount: '',
  chooseMsg1: '',
  chooseMsg2: '',
  alertMsg1: '',
  alertMsg2: '',
  secondaryMsg1: '',
  secondaryMsg2: '',
  onChangeShopId: (shopId) => {},
  onChangeSiretNumber: (siretNumber) => {},
  onChangeNbOfArticles: (nbOfArticles) => {},
  onChangeShopName: (shopName) => {},
  onChangeAddress: (address) => {},
  onChangePhone: (address) => {},
  onChangeBgColor: (bgColor) => {},
  onChangeTextColor: (textColor) => {},
  onChangeFirstMessage: (firstMessage) => {},
  onChangeMainMessage: (mainMessage) => {},
  onChangeSecondaryMessage: (secondaryMessage) => {},
  onChangeLogo: (logo) => {},
  onChangeShopAlert: (shopAlert) => {},
  onChangeDiscount: (discount) => {},
  onChangeChooseMsg1: (chooseMsg1) => {},
  onChangeChooseMsg2: (chooseMsg2) => {},
  onChangeAlertMsg1: (alertMsg1) => {},
  onChangeAlertMsg2: (alertMsg2) => {},
  onChangeSecondaryMsg1: (secondaryMsg1) => {},
  onChangeSecondaryMsg2: (secondaryMsg2) => {},
});

export function useCustomContext() {
  return useContext(customContext);
}
