import React from 'react';
import '../../themes/absolute.css';

type ModalNoLogoProps = {
  setModalNoLogo: (value: boolean) => void;
};
export default function ModalNoLogo({ setModalNoLogo }: ModalNoLogoProps) {
  return (
    <div className="mt-2 absolute top-[100%] font-neueRegular text-sm right-0 rounded-xl flex flex-col bg-modalsBg box-border pl-4 pt-4 pb-4">
      <img
        src={require('../../assets/images/losangeModale.png')}
        alt="Losange flèche haut"
        className="absolute justify-center h-[20px] w-[20px] border-solid arrowPositionModal"
      />
      <p>
        Si vous n&apos;avez pas de logo, cliquez ici pour passer à la partie "Informations du
        magasin"
      </p>
    </div>
  );
}
