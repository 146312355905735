import React, { useMemo, useState } from 'react';
import LeftCustom from './LeftCustom';
import RightCustom from './RightCustom';
import Navbar from '../../components/Navbar';
import { customContext, CustomContext, LogoType } from '../../contexts/customContext';

function Custom() {
  const [shopId, setShopId] = useState(Number);
  const [siretNumber, setSiretNumber] = useState('');
  const [nbOfArticles, setNbOfArticles] = useState(3);
  const [shopName, setShopName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [bgColor, setBgColor] = useState('white');
  const [textColor, setTextColor] = useState('black');
  const [firstMessage, setFirstMessage] = useState('');
  const [mainMessage, setMainMessage] = useState('');
  const [secondaryMessage, setSecondaryMessage] = useState('');
  const [logo, setLogo] = useState<LogoType>(null);
  const [shopAlert, setShopAlert] = useState('');
  const [discount, setDiscount] = useState(require('../../assets/images/bonDeReducJaune.png'));
  const [chooseMsg1, setChooseMsg1] = useState(
    'Les échanges se font sous 15 jours sur présentation de ce ticket et des étiquettes de l’emballage d’origine.'
  );
  const [chooseMsg2, setChooseMsg2] = useState(
    'Pour rappel, nous collectons les bouchons de bouteilles et capsules de café usagées'
  );
  const [alertMsg1, setAlertMsg1] = useState(
    'Rappel de toutes les pizzas surgelées Fraîch’Up Buitoni'
  );
  const [alertMsg2, setAlertMsg2] = useState(
    'Les échanges se font sous 15 jours sur présentation de ce ticket et des étiquettes de l’emballage d’origine.'
  );
  const [secondaryMsg1, setSecondaryMsg1] = useState(
    'Si vous possédez ce produit, ne le consommez pas!'
  );
  const [secondaryMsg2, setSecondaryMsg2] = useState(
    'Plus d informations au service client Buitoni au 0678676564.'
  );

  const context: CustomContext = useMemo(() => {
    return {
      shopId,
      siretNumber,
      nbOfArticles,
      shopName,
      address,
      phone,
      bgColor,
      textColor,
      firstMessage,
      mainMessage,
      secondaryMessage,
      logo,
      shopAlert,
      discount,
      chooseMsg1,
      chooseMsg2,
      alertMsg1,
      alertMsg2,
      secondaryMsg1,
      secondaryMsg2,
      onChangeShopId: (value) => {
        setShopId(value);
      },
      onChangeSiretNumber: (value) => {
        setSiretNumber(value);
      },
      onChangeNbOfArticles: (value) => {
        setNbOfArticles(value);
      },
      onChangeShopName: (value) => {
        setShopName(value);
      },
      onChangeAddress: (value) => {
        setAddress(value);
      },
      onChangePhone: (value) => {
        setPhone(value);
      },
      onChangeBgColor: (value) => {
        setBgColor(value);
      },
      onChangeTextColor: (value) => {
        setTextColor(value);
      },
      onChangeFirstMessage: (value) => {
        setFirstMessage(value);
      },
      onChangeMainMessage: (value) => {
        setMainMessage(value);
      },
      onChangeSecondaryMessage: (value) => {
        setSecondaryMessage(value);
      },
      onChangeLogo: (value) => {
        setLogo(value);
      },
      onChangeShopAlert: (value) => {
        setShopAlert(value);
      },
      onChangeDiscount: (value) => {
        setDiscount(value);
      },
      onChangeChooseMsg1: (value) => {
        setChooseMsg1(value);
      },
      onChangeChooseMsg2: (value) => {
        setChooseMsg2(value);
      },
      onChangeAlertMsg1: (value) => {
        setAlertMsg1(value);
      },
      onChangeAlertMsg2: (value) => {
        setAlertMsg2(value);
      },
      onChangeSecondaryMsg1(value) {
        setSecondaryMsg1(value);
      },
      onChangeSecondaryMsg2(value) {
        setSecondaryMsg2(value);
      },
    };
  }, [
    shopId,
    siretNumber,
    nbOfArticles,
    shopName,
    address,
    phone,
    bgColor,
    textColor,
    firstMessage,
    mainMessage,
    secondaryMessage,
    logo,
    shopAlert,
    discount,
    chooseMsg1,
    chooseMsg2,
    alertMsg1,
    alertMsg2,
    secondaryMsg1,
    secondaryMsg2,
  ]);

  return (
    <customContext.Provider value={context}>
      <div className="w-full h-full bg-mainBg">
        <Navbar />
        <div className="pl-[19vw] flex bg-mainBg">
          <LeftCustom />
          <RightCustom />
        </div>
      </div>
    </customContext.Provider>
  );
}

export default Custom;
