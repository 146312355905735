import React from 'react';
import '../../themes/absolute.css';
import { useCustomContext } from '../../contexts/customContext';
import ModalErrMsg from './ModalErrMsg';

function CustomMessage() {
  const customContext = useCustomContext();
  const maxCharacters = 110;
  const { firstMessage } = useCustomContext();

  return (
    <div className="w-full mt-16">
      <div>
        <p className="pb-6 font-neueUltra font-bold">Message de customisation</p>
        <textarea
          className={`block h-[8vw] relative mt-4 bg-transparent w-full rounded-2xl py-4 text-xs box-border border-solid border-[1px] flex-column justify-center items-center font-inter border-black          resize-none placeholder:text-left pl-4 placeholder:text-black placeholder:font-bold placeholder:font-inter placeholder:absolute px-2 max-rows:6 ${
            firstMessage.length === maxCharacters && 'border-red-500'
          }`}
          rows={4}
          placeholder="MESSAGE (110 CARACTERES MAX)"
          defaultValue={customContext.firstMessage}
          maxLength={110}
          onChange={(event) => {
            if (event.target.value.length <= maxCharacters) {
              customContext.onChangeFirstMessage(event.target.value);
            }
          }}
        />
        {firstMessage.length === maxCharacters && <ModalErrMsg />}
      </div>
    </div>
  );
}

export default CustomMessage;
