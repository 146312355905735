/* eslint-disable no-alert */
/* eslint-disable no-else-return */
import React from 'react';
import { useCustomContext } from '../../contexts/customContext';
import ModalErrInfos from './ModalErrInfos';
import ModalErrAddress from './ModalErrAddress';

function ShopInfos() {
  const customContext = useCustomContext();
  const maxCharacters = 30;
  const maxCharAddress = 100;
  const { shopName, address, phone, siretNumber } = useCustomContext();

  return (
    <div className="w-full mt-16">
      <div className="flex flex-col justify-between mb-8 mt-8 text-black">
        <div className="font-neueUltra">
          <p className="font-bold pb-6 " id="shopInfos">
            Informations du magasin
          </p>
          <input
            className={`bg-transparent relative border-solid border-[1px] text-left border-black font-inter font-semibold w-full h-[4vw] rounded-2xl py-4 mt-4 pl-4 text-xs flex-column
            placeholder:text-left placeholder:uppercase
            placeholder:text-black px-2 ${shopName.length === maxCharacters && 'border-red-500'}`}
            placeholder="Nom de l'entreprise"
            defaultValue={customContext.shopName}
            required
            maxLength={30}
            onChange={(event) => {
              if (event.target.value.length <= maxCharacters) {
                customContext.onChangeShopName(event.target.value);
              }
            }}
            type="text"
          />
          {shopName.length === maxCharacters && <ModalErrInfos />}
          <input
            className={`bg-transparent relative border-solid border-[1px] border-black font-inter font-semibold w-full h-[4vw] rounded-2xl py-4 mt-4 text-xs flex-column justify-center items-center
            placeholder:text-left pl-4 placeholder:uppercase
            placeholder:text-black px-2 ${address.length === maxCharAddress && 'border-red-500'}`}
            placeholder="Adresse"
            defaultValue={customContext.address}
            id="address"
            name="address"
            maxLength={100}
            required
            onChange={(event) => {
              if (event.target.value.length <= maxCharacters) {
                customContext.onChangeAddress(event.target.value);
              }
            }}
            type="text"
          />
          {address.length === maxCharAddress && <ModalErrAddress />}
          <input
            className={`bg-transparent relative border-solid border-[1px] border-black font-inter font-semibold w-full h-[4vw] rounded-2xl py-4 mt-4 text-xs flex-column justify-center items-center
            placeholder:text-left pl-4 placeholder:uppercase
            placeholder:text-black px-2 ${phone.length === maxCharacters && 'border-red-500'}`}
            placeholder="Numéro de téléphone"
            defaultValue={customContext.phone}
            required
            maxLength={30}
            onChange={(event) => {
              if (event.target.value.length <= maxCharacters) {
                customContext.onChangePhone(event.target.value);
              }
            }}
            type="text"
          />
          {phone.length === maxCharacters && <ModalErrInfos />}
          <input
            className={`bg-transparent relative border-solid border-[1px] text-left border-black font-inter font-semibold w-full h-[4vw] rounded-2xl py-4 mt-4 pl-4 text-xs flex-column
            placeholder:text-left placeholder:uppercase
            placeholder:text-black px-2 ${
              siretNumber.length === maxCharacters && 'border-red-500'
            }`}
            placeholder="Numéro de SIRET"
            defaultValue={customContext.siretNumber}
            required
            maxLength={30}
            onChange={(event) => {
              if (event.target.value.length <= maxCharacters) {
                customContext.onChangeSiretNumber(event.target.value);
              }
            }}
            type="text"
          />
        </div>
      </div>
    </div>
  );
}

export default ShopInfos;
