import React from 'react';

function ModalErrAddress() {
  return (
    <div className="text-red-700 text-xs pl-4">
      <p>Le texte doit faire moins de 100 caractères</p>
    </div>
  );
}
export default ModalErrAddress;
