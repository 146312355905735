import React, { useEffect, useState } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { customContext, CustomContext } from '../contexts/customContext';
import { store, persistor } from '../store';
import Rooter from '../router/Rooter';
import MobileWarning from '../components/ticketViewComponents/MobileWarning';
import Custom from './custom/Custom';

function MainApp() {
  const [showMobileWarning, setShowMobileWarning] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 928) setShowMobileWarning(true);
  }, []);

  return (
    <div>
      {showMobileWarning ? (
        <MobileWarning />
      ) : (
        // <PersistGate loading={null} persistor={persistor}>
        //   <Rooter />
        // </PersistGate>
        <Custom />
      )}
    </div>
    // <Provider store={store}>
    //   {showMobileWarning ? (
    //     <MobileWarning />
    //   ) : (
    //     <PersistGate loading={null} persistor={persistor}>
    //       <Rooter />
    //     </PersistGate>
    //   )}
    // </Provider>
  );
}
export default MainApp;
