import React from 'react';
import '../../themes/absolute.css';

function ModalModifyLogo() {
  return (
    <div className="w-full">
      <div className="relative rounded-2xl py-6 px-6 mt-8 mb-4 flex flex-col text-xs bg-modalsBg box-border">
        <img
          src={require('../../assets/images/losangeModale.png')}
          alt="Losange flèche haut"
          className="absolute justify-center h-[20px] w-[20px] border-solid centerPositionSquare"
        />
        <p className="text-redColor font-bold font-inter">ASTUCE</p>
        <br />
        <p className="text-left font-semibold text-sm">
          Pour une meilleure intégration de votre logo, utilisez un logo en format png ou jpg
          transparent. Evitez les logos en ton sur ton avec le fond.
        </p>
        <div className="flex flex-col lg:flex lg:flex-row w-full justify-center items-center mt-5">
          <div className="rounded-md lg:mr-4">
            <img
              src={require('../../assets/images/logoQuipoTran.png')}
              alt="Logo Quipo fond Transparent"
              className="block mx-auto box-border border-2 border-gray-400 rounded-md p-3"
            />
            <img
              src={require('../../assets/images/pastilleOk.png')}
              alt="Pastille OK"
              className="relative mx-auto pt-2 pb-2"
            />
          </div>
          <div className="justify-center items-center h-30">
            <div className="bg-transparent rounded-md box-border border-gray-400 border-2">
              <div className="block mx-auto box-border border-gray-400 rounded-md p-3">
                <img
                  src={require('../../assets/images/logoQuipoTran.png')}
                  alt="Logo Quipo fond Blanc"
                  className="bg-white"
                />
              </div>
            </div>
            <img
              src={require('../../assets/images/pastilleNotOk.png')}
              alt="Pastille Not OK"
              className="relative mx-auto pt-2 pb-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalModifyLogo;
