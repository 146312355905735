import React from 'react';

export default function ModalWhatsApp() {
  return (
    <div className="mt-2 absolute top-[100%] font-neueRegular text-sm left-0 rounded-xl flex flex-col bg-modalsBg box-border p-4">
      <img
        src={require('../../assets/images/losangeModale.png')}
        alt="Losange flèche haut"
        className="absolute justify-center h-[20px] w-[20px] border-solid arrowPositionModal"
      />
      <p>Contactez-nous au 06 38 92 33 42!</p>
    </div>
  );
}
