/* eslint-disable no-alert */
import React from 'react';
import { useCustomContext } from '../../contexts/customContext';
import ModalErrSecMsg from './ModalErrSecMsg';

function SecondMsg() {
  const customContext = useCustomContext();
  const maxCharacters = 80;
  const { secondaryMessage } = customContext;

  return (
    <div className="w-full mt-8">
      <p>Secondaire</p>
      <textarea
        className={`resize-none block h-[8vw] relative mt-4 bg-transparent w-full rounded-2xl py-4 text-xs box-border border-solid border-[1px] flex-column justify-center items-center font-inter border-black           placeholder:text-left pl-4 placeholder:text-black placeholder:font-bold placeholder:font-inter placeholder:absolute px-2 max-rows:6 ${
          secondaryMessage.length === maxCharacters && 'border-red-500'
        }`}
        placeholder="MESSAGE (80 CARACTERES MAX)"
        // className="w-[0.5vw] h-[0.5vw]"
        defaultValue={customContext.secondaryMessage}
        maxLength={80}
        rows={4}
        onChange={(event) => {
          if (event.target.value.length <= maxCharacters) {
            customContext.onChangeSecondaryMessage(event.target.value);
          }
        }}
      />
      {secondaryMessage.length === maxCharacters && <ModalErrSecMsg />}
      <div className="mt-4 flex flex-row items-center justify-center space-x-1">
        <hr className="border-black rounded-xs border-2 w-[2.5vw] hover:border-redColor hover:cursor-pointer" />
      </div>
    </div>
  );
}

export default SecondMsg;
