/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
import React from 'react';
import { useCustomContext } from '../../contexts/customContext';
import { useCashContext } from '../../contexts/cashContext';
import '../../themes/absolute.css';
import '../../themes/logos.css';
import '../../themes/customScroll.css';

const MAX_LOGO_SIZE_SQUARE = 180;
const MAX_LOGO_SIZE_OTHER = 266;

function RightCustom() {
  const customContext = useCustomContext();
  const cashContext = useCashContext();

  const {
    shopName,
    siretNumber,
    address,
    phone,
    firstMessage,
    mainMessage,
    secondaryMessage,
    logo,
  } = useCustomContext();

  const { cashDeskId } = useCashContext();

  const growUp = (height: number, width: number) => {
    const ratio = height / width;
    const size = ratio === 1 ? MAX_LOGO_SIZE_SQUARE : MAX_LOGO_SIZE_OTHER;

    if (ratio > 1) {
      return Math.abs(size - height) / ratio + width;
    }
    return Math.abs(size - width) / (width / height) + height;
  };
  const downSize = (height: number, width: number) => {
    const ratio = height / width;
    const size = ratio === 1 ? MAX_LOGO_SIZE_SQUARE : MAX_LOGO_SIZE_OTHER;

    if (ratio > 1) {
      return width - Math.abs(size - height) / ratio;
    }
    return height - Math.abs(size - width) / (width / height);
  };

  const renderHeightAndWidth = (logoHeight: number, logoWidth: number) => {
    const ratio = logoHeight / logoWidth;
    const size = ratio === 1 ? MAX_LOGO_SIZE_SQUARE : MAX_LOGO_SIZE_OTHER;

    if (ratio === 1) {
      return {
        logoHeight: size,
        logoWidth: size,
      };
    }

    if (ratio < 1) {
      return {
        logoHeight:
          logoWidth <= size ? growUp(logoHeight, logoWidth) : downSize(logoHeight, logoWidth),
        logoWidth: size,
      };
    }

    if (ratio > 1) {
      return {
        logoHeight: size,
        logoWidth:
          logoHeight <= size ? growUp(logoHeight, logoWidth) : downSize(logoHeight, logoWidth),
      };
    }

    return {
      logoHeight: size,
      logoWidth: size,
    };
  };

  const displayLogoOrShopName = () => {
    if (logo !== null) {
      const { logoWidth, logoHeight } = renderHeightAndWidth(
        logo.logoHeightOrigin,
        logo.logoWidthOrigin
      );
      console.log(logoHeight, logoWidth);

      const ratio = logo.logoHeightOrigin / logo.logoWidthOrigin;
      const size = ratio === 1 ? MAX_LOGO_SIZE_SQUARE : MAX_LOGO_SIZE_OTHER;

      return (
        <div className="h-[266px] w-[266px] justify-center items-center flex">
          <div className="justify-center items-center flex" style={{ height: size, width: size }}>
            <div style={{ width: logoWidth, height: logoHeight }}>
              {customContext.logo !== null && (
                <img src={URL.createObjectURL(customContext.logo?.file)} alt="Logo" />
              )}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="mt-5 text-xl w-full h-250px">
        {shopName.length === 0 ? 'Nom de votre magasin' : shopName}
      </div>
    );
  };

  return (
    <div className="fixed ml-[46vw] flex flex-initial w-[35vw]">
      <div className="flex w-[35vw] h-full bg-mainBg mr-6 ml-8 scale-90">
        <div className=" bg-black w-[315px] h-[750px] rounded-2xl flex flex-col items-center mb-[4vw] pt-3">
          <div
            className="mt-8 w-[300px] h-[600px] rounded-lg text-sm text-center text-[#4F13F4] border-2 border-transparent flex-column items-center overflow-y-auto overflow-x-hidden"
            style={{ backgroundColor: customContext.bgColor, color: customContext.textColor }}>
            <div className="relative">
              <div className="pt-4 text-black">
                <div className="relative flex flex-row items-center justify-center">
                  <div className="absolute mx-4 top-0 bottom-0 left-0 right-0 z-20">
                    <div className="flex flex-row items-center h-100">
                      <div className="flex-1">
                        <svg
                          width="38"
                          height="23"
                          viewBox="0 0 38 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M37.0659 11.8372L1.00014 11.8372" stroke="black" />
                          <path d="M11.6163 1.22078L1 11.837L11.6163 22.4533" stroke="black" />
                        </svg>
                      </div>
                      <div className="pr-2">
                        <svg
                          width="18"
                          height="23"
                          viewBox="0 0 18 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M8.61111 18.1055L2.33323 22.0283C1.53646 22.5262 0.550781 21.8885 0.550781 20.8754V3.24531C0.550781 1.95523 1.49503 0.909424 2.65983 0.909424H14.5625C15.7273 0.909424 16.6716 1.95523 16.6716 3.24531V20.8754C16.6716 21.8887 15.6858 22.5262 14.8891 22.0283L8.61126 18.1055H8.61111Z"
                            stroke="#1D1D1B"
                            strokeMiterlimit="10"
                          />
                        </svg>
                      </div>
                      <div>
                        <svg
                          height="23"
                          viewBox="0 0 26 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M13.007 25.5019C19.7343 25.5019 25.1879 20.066 25.1879 13.3606C25.1879 6.6551 19.7343 1.21924 13.007 1.21924C6.27973 1.21924 0.826172 6.6551 0.826172 13.3606C0.826172 20.066 6.27973 25.5019 13.007 25.5019Z"
                            stroke="black"
                            strokeWidth="1.09375"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M13.008 1.21924C10.7865 1.21924 8.71018 1.82132 6.91699 2.85661C10.5549 4.95656 13.008 8.86906 13.008 13.3606C13.008 17.8521 10.5549 21.7646 6.91699 23.8645C8.71018 24.8998 10.7865 25.5019 13.008 25.5019C19.7356 25.5019 25.1888 20.0664 25.1888 13.3606C25.1888 6.65478 19.7356 1.21924 13.008 1.21924Z"
                            stroke="black"
                            strokeWidth="1.09375"
                            strokeMiterlimit="10"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="border-[1px] border-black rounded-3xl px-2 py-1">
                      <p className="text-xs uppercase">18 sept.</p>
                    </div>
                  </div>
                  <div className="border-[1px] border-black rounded-3xl px-2 py-1">
                    <p className="text-xs uppercase">22:00</p>
                  </div>
                </div>
                <div className="mt-2 tracking-widest font-extrabold">
                  <p>N°35715820</p>
                </div>
              </div>
              <div className="mt-2 mx-4">
                {displayLogoOrShopName()}
                <div className="mt-5 text-xs">{address.length === 0 ? 'ADRESSE' : address}</div>
                <div className="text-xs">TEL: {phone.length === 0 ? '' : phone}</div>
                <div className="text-xs">
                  N° DE SIRET: {siretNumber.length === 0 ? '' : siretNumber}
                </div>
                <div className="mb-3 text-xs">
                  <span className="font-extrabold text-xs">
                    CAISSE {cashDeskId === null ? 'Numéro de caisse' : cashDeskId}
                  </span>
                </div>
              </div>
              <div className="flex flex-row text-left justify-between mx-4 text-sm">
                <div className="flex flex-col font-inter">
                  <p className="flex-1 font-bold">Qté</p>
                  <p className="pt-2 text-xs align-start uppercase">2</p>
                  <p className="pt-2 text-xs align-start uppercase">1</p>
                </div>
                <div className="flex flex-col font-inter">
                  <p className="flex-1 font-bold">Produits</p>
                  <p className="pt-2 text-xs align-start uppercase">tomates</p>
                  <p className="pt-2 text-xs align-start uppercase">cafe grains</p>
                </div>
                <div>
                  <p className="flex-1 font-bold">PU</p>
                  <p className="pt-2 text-xs align-start uppercase">1.99€</p>
                  <p className="pt-2 text-xs align-end">1.99€</p>
                </div>
                <div>
                  <p className="flex-1 font-bold mr-4">PT</p>
                  <p className="pt-2 text-xs align-end">3.98€</p>
                  <p className="pt-2 text-xs align-end">1.99€</p>
                </div>
              </div>
              <div className="flex flex-row justify-between mx-4 mb-2 mt-6">
                <p className="pt-2 text-xs align-start">TOTAL</p>
                <p className="text-xl align-end font-extrabold font-neueUltra">5.97€</p>
              </div>
              <div className="mx-4">
                <hr className="border-solid border-1 border-[#CDCDCDCD] w-full" />
                <div className="mt-6 text-left text-sm font-bold mb-6">
                  {customContext.nbOfArticles} article(s)
                </div>
                <hr
                  className="mt-4 border-dashed border-1 w-full mb-6"
                  style={{ backgroundColor: customContext.textColor }}
                />
                <div className="mt-4 text-sm font-bold text-center uppercase">
                  {firstMessage.length === 0 ? 'Message de customisation' : firstMessage}
                </div>
                <div className="flex flex-row relative mb-4">
                  <hr
                    className="flex-1 mt-6 border-dashed border-1 w-[40px] mr-3"
                    style={{ backgroundColor: customContext.textColor }}
                  />
                  <div className="absolute centerPositionSlash w-[40px]">/!\</div>
                  <hr
                    className="mt-6 border-1 border-dashed w-[120px] ml-3"
                    style={{ backgroundColor: customContext.textColor }}
                  />
                </div>
                <div
                  className="mt-4 mb-6 text-white text-sm font-bold text-center uppercase"
                  style={{ backgroundColor: customContext.textColor }}>
                  {mainMessage.length === 0 ? 'Message principal au client' : mainMessage}
                </div>
                <div className="mt-4 text-xs">
                  {secondaryMessage.length === 0
                    ? 'Message secondaire au client'
                    : secondaryMessage}
                </div>
                <div className="flex flex-row relative mb-4">
                  <hr
                    className="flex-1 mt-6 border-dashed border-1 w-[40px] mr-3"
                    style={{ backgroundColor: customContext.textColor }}
                  />
                  <div className="absolute centerPositionSlash w-[40px]">/!\</div>
                  <hr
                    className="mt-6 border-1 border-dashed w-[120px] ml-3"
                    style={{ backgroundColor: customContext.textColor }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="relative w-[300px] ">
            <div className="absolute flex flex-row w-full bottomPositionCircles">
              <div className="absolute z-20 svgLeft">
                <svg
                  width="22"
                  height="27"
                  viewBox="0 0 22 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M21.9063 13.9893C21.9063 21.169 16.086 26.9893 8.90625 26.9893C1.72655 26.9893 0.90625 21.169 0.90625 13.9893C0.90625 6.80962 1.72655 0.989319 8.90625 0.989319C16.086 0.989319 21.9063 6.80962 21.9063 13.9893Z"
                    fill="#222222"
                  />
                </svg>
              </div>
              <hr
                className="mt-3 border-2 border-dashed w-[300px]"
                style={{ border: customContext.textColor }}
              />
              <svg
                className="z-20 svgRight"
                width="20"
                height="29"
                viewBox="0 0 20 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18.2622 14.9893C18.2622 22.7213 21.9942 28.9893 14.2622 28.9893C6.53022 28.9893 0.262207 22.7213 0.262207 14.9893C0.262207 7.25733 6.53022 0.989319 14.2622 0.989319C21.9942 0.989319 18.2622 7.25733 18.2622 14.9893Z"
                  fill="#222222"
                />
              </svg>
            </div>
            <img src={customContext.discount} alt="Bon de réduction" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RightCustom;
