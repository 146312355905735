import React, { createContext, useContext } from 'react';

export type CashContext = {
  cashLicencesQty: number;
  cashDeskId: number;
  onChangeCashLicencesQty: (cashLicencesQty: number) => void;
  onChangeCashDeskId: (cashLicenceId: number) => void;
};
export const cashContext = createContext<CashContext>({
  cashLicencesQty: 1,
  cashDeskId: 9,
  onChangeCashLicencesQty: (cashLicencesQty) => {},
  onChangeCashDeskId: (cashLicenceId) => {},
});

export function useCashContext() {
  return useContext(cashContext);
}
