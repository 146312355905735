import React from 'react';

function ModalErrInfos() {
  return (
    <div className="text-red-700 text-xs pl-4">
      <p>Le texte doit faire moins de 30 caractères</p>
    </div>
  );
}
export default ModalErrInfos;
