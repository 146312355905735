import React from 'react';
import '../../themes/absolute.css';

type ModalCopyMsgProps = {
  setModalCopyMsg: (value: boolean) => void;
};
export default function ModalCopyMsg({ setModalCopyMsg }: ModalCopyMsgProps) {
  return (
    <div className="mt-6 absolute bottom-[105%] ml-[60%] font-neueRegular text-sm right-0 rounded-xl flex flex-col bg-modalsBg box-border p-4">
      <img
        src={require('../../assets/images/losangeModale.png')}
        alt="Losange flèche haut"
        className="absolute justify-center h-[20px] w-[20px] border-solid topPositionModal"
      />
      <p>Cliquez ici pour copier l&apos;exemple de message</p>
    </div>
  );
}
